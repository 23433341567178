@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Select.language {
	height: 1.11em;
	background-color: transparent;
	border: none;
	.Select-button {
		background-image: url('../../../assets/images/icons/icon-arrow-down-green.svg');
		&::before {
			content: '';
			position: absolute;
			background-image: url('../../../assets/images/icons/icon-globe-green.svg');
			height: 1.11em;
			width: 1.11em;
			left: -3em;
			background-position: left center;
		}
	}
	.Select-panel {
		border: none;
	}
	.Select-selected {
		padding: 0 1.2em 0 0;
		span {
			font-family: 'Metropolis', sans-serif;
			font-weight: bold;
			color: $green;
		}
	}
	.Select-button {
		width: 1.11em;
		height: 1.11em;
	}
	.Select-dropdown {
		width: 6.2em;
		top: calc(1.11em + 1.11em);
		left: calc(50% - 0.5em - 6.2em / 2);
		background-color: white;
		.Select-options {
			.Select-option {
				padding: 0 1.2em 0 0;
				border-radius: 0.2em;
				padding: 0.4em;
				@include flex('center', 'center');
				span {
					font-size: 0.9em;
					color: $blueDark;
				}
				&:hover {
					background-color: transparent;
					background-color: rgba($green, 0.4);
				}
			}
		}
	}
	&.open {
		.Select-button {
			@include scaleY(-1);
		}
		.Select-dropdown {
			border-radius: 0.55em;
			padding: 0.3em;
		}
	}
}