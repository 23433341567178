@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Select {
	position: relative;
	width: 100%;
	height: 1.5em;
	background-color: white;
	color: black;
	font-weight: normal;
	text-align: left;
	border-radius: 0.25em;
	cursor: pointer;
	.Select-panel {
		height: 100%;
		border: 1px solid black;
		border-radius: 0.25em;
	}
	
	.Select-selected {
		height: 100%;
		padding: 0.0em 0.5em;
		@include flex('flex-start', 'center');
	}
	.Select-button {
		position: absolute;
		top: 0;
		right: 0;
		width: 1.5em;
		height: 100%;
		background-position: center center;
		background-size: auto 0.5em;
		background-repeat: no-repeat;
	}

	.Select-dropdown {
		position: absolute;
		left: 0;
		top: calc(1.5em - 2px);
		width: 100%;
		background-color: white;
		z-index: 2;
		
		.Select-options {
			height: 0;
			@include transition('height', 0.3s, 'ease');
			overflow: auto;
			.Select-option {
				cursor: pointer;
				position: relative;
				padding: 0 0.5em;
				height: 1.5em;
				@include flex('flex-start', 'center');
				span {
					line-height: 1;
				}
				img {
					position: absolute;
					right: 0;
					max-height: 1.5em;
					max-width: 2em;
				}
				&:hover {
					background-color: darken(white, 5%);
				}
			}
		}
	}

	&.open {
		.Select-panel {
			border-radius: 0.25em 0.25em 0 0;
		}
		.Select-dropdown {
			.Select-options {
				height: auto;
				border-radius: 0 0 0.25em 0.25em;
			}
			
		}
	}
	&.disabled {
		cursor: default;
		color: black;
		background-color: rgb(225, 225, 225);
		.Select-button {display: none;}
	}
}