/* NO ANIMATION */
@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

/* FADE IN */
@mixin fadeIn($seconds: 0.5s, $delay: 0s) {
	-webkit-animation: fadeIn $seconds $delay ease-out forwards;
	animation: fadeIn $seconds $delay ease-out forwards;
  transform-origin: center center;
}
@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

/* FADE OUT */
@mixin fadeOut($seconds: 0.5s, $delay: 0s) {
	-webkit-animation: fadeOut $seconds $delay ease-out forwards;
	animation: fadeOut $seconds $delay ease-out forwards;
  transform-origin: center center;
}
@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

/* LOADING WIDTH */
@mixin loadingWidth($seconds: 1.5s, $timing: 'ease', $delay: 0s) {
  animation: loadingWidth $seconds #{$timing} $delay forwards;
  -webkit-animation: loadingWidth $seconds #{$timing} $delay forwards;
}
@-webkit-keyframes loadingWidth {
  0% {width: 0;}
  100% {width: 100%;}
}
@keyframes loadingWidth {
  0% {width: 0;}
  100% {width: 100%;}
}


/* FADE IN BUTTON */
@mixin fadeInButton($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  @include opacity(0);
  pointer-events: none;
  cursor: default;
  animation: fadeInButton $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeInButton $seconds #{$timing} $delay forwards;
}

@keyframes fadeInButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;}
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;}
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}
@-webkit-keyframes fadeInButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;}
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;}
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}

/* POP IN */
@mixin popIn($duration: 0.5s, $delay: 0s) {
  -webkit-animation: popIn $duration ease-in $delay forwards;
  animation: popIn $duration ease-in $delay forwards;  

  @-webkit-keyframes popIn {
    0% {@include scale(0);}
    40% {@include scale(1);}
    70% {@include scale(0.8);}
    100% {@include scale(1);}
  }
  @keyframes popIn {
    0% {@include scale(0);}
    40% {@include scale(1);}
    70% {@include scale(0.8);}
    100% {@include scale(1);}
  }
}

/* BOUNCE */
@mixin bounce($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);}
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

/* WOBBLE */
@mixin wobble($duration: 0.5s, $delay: 0s, $repeat: 1) {
  -webkit-animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble $duration cubic-bezier(0.280, 0.840, 0.420, 1) $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  15% {
    -webkit-transform: translate(-5%, 0) rotate(-3deg);
    transform: translate(-5%, 0) rotate(-3deg);
  }
  30% {
    -webkit-transform: translate(4%, 0) rotate(2deg);
    transform: translate(4%, 0) rotate(2deg);
  }
  45% {
    -webkit-transform: translate(-3%, 0) rotate(-2deg);
    transform: translate(-3%, 0) rotate(-2deg);
  }
  60% {
    -webkit-transform: translate(3%, 0) rotate(1deg);
    transform: translate(3%, 0) rotate(1deg);
  }
  75% {
    -webkit-transform: translate(-2%, 0) rotate(-1deg);
    transform: translate(-2%, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes wobble {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  15% {
    -webkit-transform: translate(-5%, 0) rotate(-3deg);
    transform: translate(-5%, 0) rotate(-3deg);
  }
  30% {
    -webkit-transform: translate(4%, 0) rotate(2deg);
    transform: translate(4%, 0) rotate(2deg);
  }
  45% {
    -webkit-transform: translate(-3%, 0) rotate(-2deg);
    transform: translate(-3%, 0) rotate(-2deg);
  }
  60% {
    -webkit-transform: translate(3%, 0) rotate(1deg);
    transform: translate(3%, 0) rotate(1deg);
  }
  75% {
    -webkit-transform: translate(-2%, 0) rotate(-1deg);
    transform: translate(-2%, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

/* SHAKE */
@mixin shake($duration: 0.1s, $delay: 0s, $repeat: 10) {
  -webkit-animation: shake $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: shake $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  100% {@include translate(0, 0);}
}
@keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  0%, 100% {@include translate(0, 0);}
}

// GLIDE ACROSS
@mixin glideAcross($duration: 1s, $delay: 0s) {
  -webkit-animation: glideAcross $duration ease-in $delay infinite;
  animation: glideAcross $duration ease-in $delay infinite;
  @include opacity(0);

  @-webkit-keyframes glideAcross {
    0% {
      @include translate(-30%);
      @include opacity(1);
    }
    100% {
      @include translate(130%);
      @include opacity(1);
    }
  }
  @keyframes glideAcross {
    0% {
      @include translate(-30%);
      @include opacity(1);
    }
    100% {
      @include translate(130%);
      @include opacity(1);
    }
  }
}

@mixin birdFlying($duration: 6s, $delay: 0s) {
  -webkit-animation: birdFlying $duration ease-in $delay forwards;
  animation: birdFlying $duration ease-in $delay forwards;
  @-webkit-keyframes birdFlying {
    0% {
      @include translateAndScale(0, 0, -1);
    }
    100% {
      @include translateAndScale(-120vw, 0, -1);
    }
  }
  @keyframes birdFlying {
    0% {
      @include translateAndScale(0, 0, -1);
    }
    100% {
      @include translateAndScale(-120vw, 0, -1);
    }
  }
}


@mixin truckDriving($duration, $delay, $from, $midPoint, $to) {
  $animation-name: unique-id() !global;

  @include opacity(0);

	@-webkit-keyframes #{$animation-name} {
		0% {
      @include translate($from);
      @include opacity(1);
    }
    25% {
      @include translate($from);
    }
    46% {@include translate($midPoint)}
		79% {@include translate($midPoint)}
		100% {
      @include translate($to);
      @include opacity(1);
    }
	}

	@keyframes #{$animation-name} {
		0% {
      @include translate($from);
      @include opacity(1);
    }
    25% {
      @include translate($from);
    }
    46% {@include translate($midPoint)}
		79% {@include translate($midPoint)}
		100% {
      @include translate($to);
      @include opacity(1);
    }
	}

	-webkit-animation: $animation-name $duration ease-in $delay infinite;
	animation: $animation-name $duration ease-in $delay infinite;
}

@mixin driveForthThenBack($duration: 1s, $delay: 0s, $fromX, $midX, $toX) {
  -webkit-animation: driveForthThenBack $duration ease-in-out $delay infinite;
  animation: driveForthThenBack $duration ease-in-out $delay infinite;

  @-webkit-keyframes driveForthThenBack {
    0% {
      @include translate($fromX, 0);
    }
    48% {
      @include translate($fromX, 0);
    }
    55% {
      @include translate($midX, 0);
    }
    60% {
      @include translate($midX, 0);
    }
    65% {
      @include translate($toX, 0);
    }
    70% {
      @include translate($toX, 0);
    }
    77% {
      @include translate($fromX, 0);
    }
    100% {
      @include translate($fromX, 0);
    }
  }
  @keyframes driveForthThenBack {
    0% {
      @include translate($fromX, 0);
    }
    48% {
      @include translate($fromX, 0);
    }
    55% {
      @include translate($midX, 0);
    }
    60% {
      @include translate($midX, 0);
    }
    65% {
      @include translate($toX, 0);
    }
    70% {
      @include translate($toX, 0);
    }
    77% {
      @include translate($fromX, 0);
    }
    100% {
      @include translate($fromX, 0);
    }
  }
}

@mixin goUpAndDown($duration: 1s, $delay: 0s, $fromY, $middleY, $toY) {
  -webkit-animation: goUpAndDown $duration ease-in-out $delay infinite;
  animation: goUpAndDown $duration ease-in-out $delay infinite;

  @-webkit-keyframes goUpAndDown {
    0% {
      @include translate(0, $fromY);
    }
    55% {
      @include translate(0, $fromY);
    }
    60% {
      @include translate(0, $toY);
    }
    65% {
      @include translate(0, $toY);
    }
    70% {
      @include translate(0, $middleY);
    }
    80% {
      @include translate(0, $middleY);
    }
    85% {
      @include translate(0, $fromY);
    }
    100% {
      @include translate(0, $fromY);
    }
  }
  @keyframes goUpAndDown {
    0% {
      @include translate(0, $fromY);
    }
    55% {
      @include translate(0, $fromY);
    }
    60% {
      @include translate(0, $toY);
    }
    65% {
      @include translate(0, $toY);
    }
    70% {
      @include translate(0, $middleY);
    }
    80% {
      @include translate(0, $middleY);
    }
    85% {
      @include translate(0, $fromY);
    }
    100% {
      @include translate(0, $fromY);
    }
  }
}

@mixin leaveCrate($duration: 1s, $delay: 0s) {
  -webkit-animation: leaveCrate $duration ease-in-out $delay infinite;
  animation: leaveCrate $duration ease-in-out $delay infinite;
  @include opacity(0);
  @-webkit-keyframes leaveCrate {
    0% {
      @include opacity(0);
    }
    20% {
      @include opacity(1);
    }
    65% {
      @include opacity(1);
    }
    70% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
  @keyframes leaveCrate {
    0% {
      @include opacity(0);
    }
    20% {
      @include opacity(1);
    }
    65% {
      @include opacity(1);
    }
    70% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
}

@mixin unloadTractor($duration: 1s, $delay: 0s) {
  -webkit-animation: unloadTractor $duration ease-in-out $delay infinite;
  animation: unloadTractor $duration ease-in-out $delay infinite;

  @-webkit-keyframes unloadTractor {
    0% {
      @include translate(0, 0);
    }
    49% {
      @include translate(0, 0);
    }
    50% {
      @include translate(0, 7.5%);
    }
    59% {
      @include translate(0, 7.5%);
    }
    60% {
      @include translate(0, 15%);
    }
    100% {
      @include translate(0, 15%);
    }
  }
  @keyframes unloadTractor {
    0% {
      @include translate(0, 0);
    }
    49% {
      @include translate(0, 0);
    }
    50% {
      @include translate(0, 7.5%);
    }
    59% {
      @include translate(0, 7.5%);
    }
    60% {
      @include translate(0, 15%);
    }
    100% {
      @include translate(0, 15%);
    }
  }
}

@mixin airplaneTakeoff($duration: 1s, $delay: 1s, $fromX, $midX, $toX, $toY) {
  -webkit-animation: airplaneTakeoff $duration ease-in-out $delay infinite;
  animation: airplaneTakeoff $duration ease-in-out $delay infinite;
  @include opacity(0);

  @-webkit-keyframes airplaneTakeoff {
    0% {
      @include opacity(0);
      @include translate($fromX, 0);
    }
    10% {
      @include opacity(1);
      @include translate($fromX, 0);
    }
    18% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    40% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    60% {
      @include opacity(1);
      @include translate($toX, $toY);
    }
    100% {
      @include opacity(0);
      @include translate($toX, $toY);
    }
  }

  @keyframes airplaneTakeoff {
    0% {
      @include opacity(0);
      @include translate($fromX, 0);
    }
    10% {
      @include opacity(1);
      @include translate($fromX, 0);
    }
    18% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    40% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    60% {
      @include opacity(1);
      @include translate($toX, $toY);
    }
    100% {
      @include opacity(0);
      @include translate($toX, $toY);
    }
  }
}

@mixin airplaneShadow($duration: 1s, $delay: 1s, $fromX, $midX, $toX, $toY) {
  -webkit-animation: airplaneShadow $duration ease-in-out $delay infinite;
  animation: airplaneShadow $duration ease-in-out $delay infinite;
  @include opacity(0);

  @-webkit-keyframes airplaneShadow {
    0% {
      @include opacity(0);
      @include translate($fromX, 0);
    }
    10% {
      @include opacity(1);
      @include translate($fromX, 0);
    }
    18% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    40% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    45% {
      @include opacity(0);
    }
    60% {
      @include opacity(0);
      @include translate($toX, 0);
    }
    100% {
      @include opacity(0);
      @include translate($toX, 0);
    }
  }

  @keyframes airplaneShadow {
    0% {
      @include opacity(0);
      @include translate($fromX, 0);
    }
    10% {
      @include opacity(1);
      @include translate($fromX, 0);
    }
    18% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    40% {
      @include opacity(1);
      @include translate($midX, 0);
    }
    45% {
      @include opacity(0);
    }
    60% {
      @include opacity(0);
      @include translate($toX, 0);
    }
    100% {
      @include opacity(0);
      @include translate($toX, 0);
    }
  }
}

@mixin fadeInDriveToPauseDriveOut($duration: 1s, $delay: 1s, $fromX, $midX, $toX) {
  -webkit-animation: fadeInDriveToPauseDriveOut $duration ease-in-out $delay infinite;
  animation: fadeInDriveToPauseDriveOut $duration ease-in-out $delay infinite;
  @include opacity(0);

  @-webkit-keyframes fadeInDriveToPauseDriveOut {
    0% {
      @include opacity(0);
      @include translateX($fromX);
    }
    5% {
      @include opacity(1);
      @include translateX($fromX);
    }
    20% {
      @include opacity(1);
      @include translateX($fromX);
    }
    30% {
      @include opacity(1);
      @include translateX($midX);
    }
    50% {
      @include opacity(1);
      @include translateX($midX);
    }
    60% {
      @include opacity(1);
      @include translateX($toX);
    }
    100% {
      @include opacity(0);
      @include translateX($toX);
    }
  }
  @keyframes fadeInDriveToPauseDriveOut {
    0% {
      @include opacity(0);
      @include translateX($fromX);
    }
    5% {
      @include opacity(1);
      @include translateX($fromX);
    }
    20% {
      @include opacity(1);
      @include translateX($fromX);
    }
    30% {
      @include opacity(1);
      @include translateX($midX);
    }
    50% {
      @include opacity(1);
      @include translateX($midX);
    }
    60% {
      @include opacity(1);
      @include translateX($toX);
    }
    100% {
      @include opacity(0);
      @include translateX($toX);
    }
  }
}

@mixin flyingUFO($duration, $delay, $from, $midPoint, $to) {
	-webkit-animation: flyingUFO $duration ease-in $delay 1;
	animation: flyingUFO $duration ease-in $delay 1;
  @include opacity(0);
	@-webkit-keyframes flyingUFO {
		0% {@include translate($from, -12em); @include opacity(0);}
    1% {@include translate($from, -12em); @include opacity(1);}
    46% {@include translate($midPoint); @include opacity(1);}
		79% {@include translate($midPoint); @include opacity(1);}
		99% {@include translate($to, -20em); @include opacity(1);}
    99% {@include translate($to, -20em); @include opacity(0);}
	}
	@keyframes flyingUFO {
		0% {@include translate($from, -12em); @include opacity(0);}
    1% {@include translate($from, -12em); @include opacity(1);}
    46% {@include translate($midPoint); @include opacity(1);}
		79% {@include translate($midPoint); @include opacity(1);}
		99% {@include translate($to, -20em); @include opacity(1);}
    99% {@include translate($to, -20em); @include opacity(0);}
	}
}
@mixin ufoPod($duration, $delay) {
  -webkit-animation: ufoPod $duration linear $delay 1;
	animation: ufoPod $duration linear $delay 1;
  @-webkit-keyframes ufoPod {
    0% {transform: rotate(-2deg);}
    6% {transform: rotate(2deg);}
    16% {transform: rotate(-2deg);}
    26% {transform: rotate(2deg);}
    36% {transform: rotate(-2deg);}
    45% {transform: translatey(2em) rotate(0deg); animation-timing-function: ease-in;}
    55% {transform: translatey(0em);}
    65% {transform: translatey(2em);}
    79% {transform: translatey(0em); animation-timing-function: ease-out;}
    88% {transform: translatey(2em) rotate(2deg);}
    92% {transform: translatey(0em) rotate(-2deg);}
		100% {transform: rotate(2deg);}
	}
  @keyframes ufoPod {
    0% {transform: rotate(-2deg);}
    6% {transform: rotate(2deg);}
    16% {transform: rotate(-2deg);}
    26% {transform: rotate(2deg);}
    36% {transform: rotate(-2deg);}
    45% {transform: translatey(2em) rotate(0deg); animation-timing-function: ease-in;}
    55% {transform: translatey(0em);}
    65% {transform: translatey(2em);}
    79% {transform: translatey(0em); animation-timing-function: ease-out;}
    88% {transform: translatey(2em) rotate(2deg);}
    92% {transform: translatey(0em) rotate(-2deg);}
		100% {transform: rotate(2deg);}
  }
}
@mixin ufoBeam($duration, $delay) {
	-webkit-animation: ufoBeam $duration ease-in $delay infinite;
	animation: ufoBeam $duration ease-in $delay infinite;
  @include opacity(0);
	@-webkit-keyframes ufoBeam {
		0% {@include opacity(0);}
    46% {@include opacity(0);}
    48% {@include opacity(0.5);}
		75% {@include opacity(0.5);}
    76% {@include opacity(0);}
		100% {@include opacity(0);}
	}
	@keyframes ufoBeam {
    0% {@include opacity(0);}
    46% {@include opacity(0);}
    48% {@include opacity(0.5);}
		75% {@include opacity(0.5);}
    76% {@include opacity(0);}
		100% {@include opacity(0);}
	}
}
@mixin beamUp($duration, $delay, $from, $to) {
	-webkit-animation: beamUp $duration ease-in $delay infinite;
	animation: beamUp $duration ease-in $delay infinite;
  @include opacity(0);
	@-webkit-keyframes beamUp {
		0% {@include translate(0, $from); @include opacity(0);}
    47% {@include translate(0, $from); @include opacity(0);}
    48% {@include translate(0, $from); @include opacity(1);}
		72% {@include translate(0, $to); @include opacity(1);}
    73% {@include translate(0, $to); @include opacity(0);}
		100% {@include translate(0, $to); @include opacity(0);}
	}
	@keyframes beamUp {
    0% {@include translate(0, $from); @include opacity(0);}
    47% {@include translate(0, $from); @include opacity(0);}
    48% {@include translate(0, $from); @include opacity(1);}
		72% {@include translate(0, $to); @include opacity(1);}
    73% {@include translate(0, $to); @include opacity(0);}
		100% {@include translate(0, $to); @include opacity(0);}
	}
}

@mixin transitionList($duration: 0.5s) {
  -webkit-animation: transitionList $duration linear 0s forwards;
  animation: transitionList $duration linear 0s forwards;
  transform-origin: top;

  @-webkit-keyframes transitionList {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @keyframes transitionList {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
}

@mixin blink-on-off($duration: 1s, $delay: 0s) {
  -webkit-animation: blink-on-off $duration ease-in-out $delay infinite;
  animation: blink-on-off $duration ease-in-out $delay infinite;

  @-webkit-keyframes blink-on-off {
    0% {
      @include opacity(1);
    }
    32% {
      @include opacity(1);
    }
    33% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
  @keyframes blink-on-off {
    0% {
      @include opacity(1);
    }
    32% {
      @include opacity(1);
    }
    33% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
}

@mixin shipSailing($duration, $delay, $from, $midPoint, $to) {
	-webkit-animation: shipSailing $duration $delay 1;
	animation: shipSailing $duration $delay 1;
  @include opacity(0);

	@-webkit-keyframes shipSailing {
		0% {
      @include translate($from);
      @include opacity(1);
      animation-timing-function: ease-out;
    }
    40% {@include translate($midPoint);}
		60% {
      @include translate($midPoint);
      animation-timing-function: ease-in;
    }
		100% {
      @include translate($to);
      @include opacity(1);
    }
	} 

	@keyframes shipSailing {
    0% {
      @include translate($from);
      @include opacity(1);
      animation-timing-function: ease-out;
    }
    40% {@include translate($midPoint);}
		60% {
      @include translate($midPoint);
      animation-timing-function: ease-in;
    }
		100% {
      @include translate($to);
      @include opacity(1);
    }
	}
}

@mixin shipOffLoad($duration, $delay) {
	-webkit-animation: shipOffLoad $duration ease-in $delay infinite;
	animation: shipOffLoad $duration ease-in $delay infinite;
  @include opacity(0);

	@-webkit-keyframes shipOffLoad {
    0% {opacity: 1;}
		40% {opacity: 1;}
		55% {opacity: 0;}
    100% {opacity: 0;}
	} 

	@keyframes shipOffLoad {
    0% {opacity: 1;}
		40% {opacity: 1;}
		55% {opacity: 0;}
    100% {opacity: 0;}
	}
}
