@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button.cpanel {
	border-radius: 1.33em;

	&.rounded {
		border-radius: 3.33em;
	}

	&.loading {
		&::after {
			background-image: url('../../../assets/images/cpanel/icons/icon-loading.svg');
		}
	}

	&.purple {
		background-color: $cpPurple;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $cpTextDark;
		}
		&:hover {
			background-color: darken($cpPurple, 5%);
		}
	}

	&.purple-trans {
		background-color: transparent;
		border: 0.11em solid $cpPurple;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $cpTextDark;
		}
		&:hover {
			background-color: rgba($cpPurple, 0.1);
		}
	}

	&.purple-light {
		background-color: $cpPurpleLight;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $cpTextDark;
		}
		&:hover {
			background-color: $cpPurple;
		}
	}
	&.purple-light-trans {
		border-radius: 0.83em;
		background-color: transparent;
		border: 0.11em solid $cpPurpleLight;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $cpPurpleLight;
		}
		&:hover {
			background-color: rgba($cpPurpleLight, 0.1);
		}
	}
	
	&.normalTextSize span {
		font-size: 1em; // 18px 
	}
	&.smallText span {
		font-size: 0.78em; // 14px
	}
}